import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AdditionalNavigation from 'src/components/Shared/AdditionalNavigation';
// import AppRole from 'src/constants/Shared/AppRole';
// import useUserHasRole from 'src/hooks/Shared/useUserHasRole';
// import Reports from 'src/screens/Home/Reports';
import Dashboard from '../screens/Home/Dashboard';

export default function Home() {
  const { path } = useRouteMatch();
  // const userHasRole = useUserHasRole();

  // const userCanSeeReports = useMemo(() => {
  //   const roles =
  //     AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
  //     AppRole.PARKLIO_INTERNAL_ADMIN |
  //     AppRole.PMS_SUPER_ADMIN |
  //     AppRole.PMS_ADMIN;
  //   return userHasRole(roles);
  // }, [userHasRole]);

  return (
    <>
      <AdditionalNavigation>
        <AdditionalNavigation.Link exact to={path}>
          Dashboard
        </AdditionalNavigation.Link>

        {/* {userCanSeeReports && (
          <AdditionalNavigation.Link exact to={`${path}/reports`}>
            Reports
          </AdditionalNavigation.Link>
        )} */}
      </AdditionalNavigation>
      <Switch>
        <Route exact path={`${path}/`}>
          <Dashboard />
        </Route>

        {/* {userCanSeeReports && (
          <Route exact path={`${path}/reports`}>
            <Reports />
          </Route>
        )} */}
        <Route>
          <h3>404 PAGE</h3>
        </Route>
      </Switch>
    </>
  );
}
