import type ProductLicensesFormValues from 'src/models/License/ProductLicenseFormValues';
import type ProductLicensesRequestValues from 'src/models/License/ProductLicensesRequestValues';

export default function toProductLicenseAddRequest(
  data: ProductLicensesFormValues
): ProductLicensesRequestValues {
  const {
    accountId,
    barriers,
    bollards,
    brains,
    chains,
    parkingPlaces,
    lprCameras,
    gates,
  } = data;

  return {
    account_id: accountId,
    barriers: barriers,
    lpr_cameras: lprCameras,
    brains: brains,
    chains: chains,
    gates: gates,
    bollards: bollards,
    parking_places: parkingPlaces,
  };
}
