import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SendInquiryModal from 'src/components/Home/SendInquiryModal';
import LicenseInfoTable from 'src/components/Settings/LicenseInfoTable';
import PmsLicenseTable from 'src/components/Settings/PmsLicenseTable';
import Form from 'src/components/Shared/Form';

import { useGlobalFailureModal } from 'src/components/Shared/Modals/GlobalFailureModal';
import GlobalModal from 'src/components/Shared/Modals/GlobalModal';
import { useGlobalSuccessModal } from 'src/components/Shared/Modals/GlobalSuccessModal';
import Pagination from 'src/components/Shared/Pagination';

import PaginationSize from 'src/constants/Shared/DataSize';
import InitialMetaData from 'src/constants/Shared/InitialMetaData';

import PaginationItemDisplay from 'src/constants/Shared/PaginationItemDisplay';

import { useGlobalModal } from 'src/hooks/Shared/useGlobalModal';
import toProductLicenses from 'src/mappers/License/toProductLicenses';

import type AccountProductLicenses from 'src/models/License/AccountProductLicenses';
import type AddNewLicenseFormValues from 'src/models/License/AddNewLicenseFormValues';
import type ProductLicenses from 'src/models/License/ProductLicenses';
import type Meta from 'src/models/Shared/Meta';

import useAccountService from 'src/services/Home/useAccountService';
import useProductLicenseService from 'src/services/License/useProductLicenseService';
import * as yup from 'yup';
import AppRole from '../../constants/Shared/AppRole';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import useUserHasRole from '../../hooks/Shared/useUserHasRole';

const addLicenseInitialValues: AddNewLicenseFormValues = {
  gates: 0,
  parkingPlaces: 0,
  barriers: 0,
  brains: 0,
  lprCameras: 0,
  chains: 0,
  bollards: 0,
};

const addLicenseValidationSchema = yup
  .object()
  .shape({
    gates: yup
      .number()
      .integer()
      .min(0, 'Value must be greater than 0')
      .required(),
    parkingPlaces: yup
      .number()
      .integer()
      .min(0, 'Value must be greater than 0')
      .required(),
    barriers: yup
      .number()
      .integer()
      .min(0, 'Value must be greater than 0')
      .required(),
    brains: yup
      .number()
      .integer()
      .min(0, 'Value must be greater than 0')
      .required(),
    lprCameras: yup
      .number()
      .integer()
      .min(0, 'Value must be greater than 0')
      .required(),
    chains: yup
      .number()
      .integer()
      .min(0, 'Value must be greater than 0')
      .required(),
    bollards: yup
      .number()
      .integer()
      .min(0, 'Value must be greater than 0')
      .required(),
  })
  .test(
    'at-least-one-count',
    'At least one counter field must be larger than 0',
    function (values) {
      const countFields = [
        'gates',
        'parkingPlaces',
        'barriers',
        'brains',
        'lprCameras',
        'chains',
        'bollards',
      ];

      const hasCountLargerThanOne = countFields.some(
        (field) => values[field] > 0
      );

      if (!hasCountLargerThanOne) {
        return this.createError({
          path: 'at-least-one-count',
          message: 'At least one count field must be larger than 0',
        });
      }
      return true;
    }
  );

interface AccountLicenseListProps {
  accountId: number;
}

export default function AccountLicenseList(props: AccountLicenseListProps) {
  const { accountId } = props;
  const [data, setData] = useState<ProductLicenses[]>([]);
  const [totalLicenses, setTotalLicenses] = useState<
    AccountProductLicenses | undefined
  >(undefined);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [inquiryResponseInfo, setInquiryResponseInfo] = useState<
    { success: boolean; message: string } | undefined
  >(undefined);
  const isMounted = useIsMounted();
  const [meta, setMeta] = useState<Meta>(InitialMetaData);
  const { currentPage } = meta;
  const userHasRole = useUserHasRole();

  const {
    getProductLicenses,
    addNewProductLicense,
    updateProductLicense,
    removeProductLicense,
  } = useProductLicenseService();
  const { getAccountProductLicensesParklioInternal } = useAccountService();
  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});
  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: 'License is removed successfully!',
  });

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PARKLIO_INTERNAL_OPERATOR;

    return userHasRole(roles);
  }, [userHasRole]);

  const pmsUser = useMemo(() => {
    const roles =
      AppRole.PMS_SUPER_ADMIN | AppRole.PMS_ADMIN | AppRole.PMS_OPERATOR;

    return userHasRole(roles);
  }, [userHasRole]);

  const [openAddLicenseModal, closeAddLicenseModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <Form
        name='addLicense'
        initialValues={addLicenseInitialValues}
        validationSchema={addLicenseValidationSchema}
        onSubmit={onSubmitAddLicense}
      >
        <SendInquiryModal
          closeParentModal={closeInquiryModal}
          onTryAgainClick={onTryAgainClick}
          inquiryResponseInfo={inquiryResponseInfo}
        />
      </Form>
    </GlobalModal>
  ));

  const getTotalData = useCallback(async () => {
    try {
      if (!userIsParklioInternal) {
        return;
      }
      const data = await getAccountProductLicensesParklioInternal(accountId);

      if (isMounted()) {
        setTotalLicenses(data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    accountId,
    getAccountProductLicensesParklioInternal,
    isMounted,
    userIsParklioInternal,
  ]);

  const onSubmitAddLicense = useCallback(
    async (values: AddNewLicenseFormValues) => {
      try {
        const licenceResponse = await addNewProductLicense({
          accountId,
          ...values,
        });

        getTotalData();

        if (isMounted()) {
          setInquiryResponseInfo({
            success: true,
            message: 'License added successfully.',
          });
          setData((oldData) => [
            ...oldData,
            toProductLicenses(licenceResponse),
          ]);
        }
      } catch (error) {
        console.log(error);
        if (isMounted()) {
          setInquiryResponseInfo({
            success: false,
            message: 'Sorry, there is some error... Please try again.',
          });
        }
      }
    },
    [isMounted, addNewProductLicense, accountId, getTotalData]
  );

  const onTryAgainClick = useCallback(() => {
    setInquiryResponseInfo(undefined);
  }, []);

  const closeInquiryModal = useCallback(() => {
    closeAddLicenseModal();
    setInquiryResponseInfo(undefined);
  }, [closeAddLicenseModal]);

  const getData = useCallback(
    async (page: number) => {
      try {
        if (!userIsParklioInternal) {
          return;
        }
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { data, meta } = await getProductLicenses({
          accountId,
          page,
          size: PaginationSize.STANDARD,
        });
        if (isMounted()) {
          setData(data);
          if (meta !== undefined) {
            setMeta(meta);
          }
          setIsDataLoading(false);
        }
      } catch (error: any) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [userIsParklioInternal, accountId, isMounted, getProductLicenses]
  );

  useEffect(() => {
    getData(1);
  }, [getData]);

  useEffect(() => {
    getTotalData();
  }, [getTotalData]);

  const onToggleChange = useCallback(
    async (id: number, active: boolean) => {
      try {
        await updateProductLicense(id, { active: !active });
        getTotalData();

        if (isMounted()) {
          setData((oldData) => {
            const index = oldData.findIndex((data) => data.id === id);

            if (index !== -1) {
              oldData[index].active = !oldData[index].active;
            }

            return [...oldData];
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [updateProductLicense, isMounted, getTotalData]
  );

  const onRemove = useCallback(
    async (id: number) => {
      try {
        await removeProductLicense(id);

        if (data.length === 1 && currentPage > 1) {
          getData(currentPage - 1);
        }
        getData(currentPage);
        getTotalData();

        if (isMounted()) {
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      removeProductLicense,
      getData,
      isMounted,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setMessage,
      currentPage,
      data.length,
      getTotalData,
    ]
  );

  return (
    <>
      {userIsParklioInternal && (
        <React.Fragment>
          <LicenseInfoTable
            licenseData={data}
            isLoading={isDataLoading}
            openAddLicenseModal={openAddLicenseModal}
            totalLicenses={totalLicenses}
            onToggleChange={onToggleChange}
            onRemove={onRemove}
          />
          {meta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
            <Pagination meta={meta} getData={getData} />
          )}
        </React.Fragment>
      )}
      {pmsUser && <PmsLicenseTable />}
    </>
  );
}
